import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

const Home = () => {
  return (
    <>
      <section className='home-section'>
        <div className='intro-container'>
          <h1>Hi, my name is</h1>
          <h2>
            <span>Hannah Hill</span>
          </h2>

          <h2>
            I'm a junior software engineer based in London, with experience
            building fluid, full-stack web applications.
          </h2>
          <h3>
            Get in touch{' '}
            <FontAwesomeIcon icon={faArrowRight} className='arrow-icon' />{' '}
            <span>
              <a href='mailto:hillhj@icloud.com'>hillhj@icloud.com</a>
            </span>
          </h3>
        </div>
      </section>
    </>
  )
}

export default Home
