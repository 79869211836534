const Header = ({
  handleAboutClick,
  handleTechnologiesClick,
  handleProjectsClick,
  handleContactClick,
}) => {
  return (
    <>
      <header>
        <div>
          <nav>
            <ul>
              <li
                className='nav-item'
                value='aboutRef'
                onClick={handleAboutClick}
              >
                About
              </li>
              <li
                className='nav-item'
                value='technologiesRef'
                onClick={handleTechnologiesClick}
              >
                Technologies
              </li>
              <li className='nav-item' onClick={handleProjectsClick}>
                Projects
              </li>
              <li className='nav-item' onClick={handleContactClick}>
                Contact
              </li>
            </ul>
          </nav>
        </div>
      </header>
    </>
  )
}

export default Header
