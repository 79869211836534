import { forwardRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub } from '@fortawesome/free-brands-svg-icons'
import { faLink } from '@fortawesome/free-solid-svg-icons'
import mockup1 from '../images/project1_mockup.png'
import mockup2 from '../images/project2_mockup.png'
import mockup3 from '../images/project3_mockup.png'
import mockup4 from '../images/project4_mockup.png'

const Projects = forwardRef((props, ref) => {
  return (
    <section id='projects' ref={ref}>
      <div className='section-container'>
        <div className='section-subheading'>PROJECTS</div>
        <div className='section-content projects'>
          <div className='project-container'>
            <div className='project-top'>
              <div className='mockup'>
                <a
                  href='https://hh-crowd.herokuapp.com/'
                  target='_blank'
                  rel='noreferrer'
                >
                  <img src={mockup4} alt='project 4 mockup' />
                </a>
              </div>
              <div className='project-title'>
                <h4>Crowd</h4>
                <div className='project-description'>
                  <p>
                    One week to build a full-stack application using a
                    relational database. I chose to work solo to consolidate my
                    learnings and built a crowdfunding app, rooted in a
                    relational database. I programmed CRUD functionality across
                    several models, allowing users to create and pledge towards
                    crowdfunding campaigns.
                  </p>
                </div>
              </div>
            </div>
            <div className='project-languages'>
              <ul>
                <li>Python</li>
                <li>Django</li>
                <li>PostgreSQL</li>
                <li>React</li>
                <li>Sass</li>
                <li>HTML</li>
                <li>CSS</li>
                <li>Bootstrap</li>
              </ul>
            </div>
            <div className='project-links'>
              <a href='https://github.com/hannah-hill/sei-project-04'>
                <FontAwesomeIcon icon={faGithub} className='project-icon' />
              </a>
              <a href='https://hh-crowd.herokuapp.com/'>
                <FontAwesomeIcon icon={faLink} className='project-icon' />
              </a>
            </div>
          </div>
          <div className='project-container'>
            <div className='project-top'>
              <div className='mockup'>
                <a
                  href='https://aioli-app.herokuapp.com/'
                  target='_blank'
                  rel='noreferrer'
                >
                  <img src={mockup3} alt='project 3 mockup' />
                </a>
              </div>
              <div className='project-title'>
                <h4>aioli</h4>
                <div className='project-description'>
                  <p>
                    Briefed to produce a full-stack MERN application with CRUD
                    capability, my group built a recipe sharing app with our own
                    RESTful API. Among other features, I specifically owned
                    building search functionality, ‘saving’ recipes to a user
                    profile with relationships, and integrating Bootstrap
                    components.
                  </p>
                </div>
              </div>
            </div>
            <div className='project-languages'>
              <ul>
                <li>MongoDB</li>
                <li>Mongoose</li>
                <li>Express</li>
                <li>Node.js</li>
                <li>React</li>
                <li>Sass</li>
                <li>HTML</li>
                <li>CSS</li>
                <li>Bootstrap</li>
                <li>Postman</li>
                <li>Insomnia</li>
              </ul>
            </div>
            <div className='project-links'>
              <a href='https://github.com/hannah-hill/sei-project-03'>
                <FontAwesomeIcon icon={faGithub} className='project-icon' />
              </a>
              <a href='https://aioli-app.herokuapp.com/'>
                <FontAwesomeIcon icon={faLink} className='project-icon' />
              </a>
            </div>
          </div>
          <div className='project-container'>
            <div className='project-top'>
              <div className='mockup'>
                <a
                  href='https://footprint-app.netlify.app/'
                  target='_blank'
                  rel='noreferrer'
                >
                  <img src={mockup2} alt='project 2 mockup' />
                </a>
              </div>
              <div className='project-title'>
                <h4>Footprint</h4>
                <div className='project-description'>
                  <p>
                    In a pair programming hackathon, we were challenged to build
                    a React app in 48 hours. We used JavaScript, React, Sass,
                    axios, and the Carbon Interface API to build an app that
                    calculates carbon offsets for user inputted travel.
                  </p>
                </div>
              </div>
            </div>
            <div className='project-languages'>
              <ul>
                <li>JavaScript</li>
                <li>React</li>
                <li>Sass</li>
                <li>Axios</li>
                <li>HTML</li>
                <li>CSS</li>
                <li>Carbon Interface API</li>
              </ul>
            </div>
            <div className='project-links'>
              <a href='https://github.com/hannah-hill/sei-project-02'>
                <FontAwesomeIcon icon={faGithub} className='project-icon' />
              </a>
              <a href='https://footprint-app.netlify.app/'>
                <FontAwesomeIcon icon={faLink} className='project-icon' />
              </a>
            </div>
          </div>
          <div className='project-container'>
            <div className='project-top'>
              <div className='mockup'>
                <a
                  href='https://hannah-hill.github.io/sei-project-01/'
                  target='_blank'
                  rel='noreferrer'
                >
                  <img src={mockup1} alt='project 1 mockup' />
                </a>
              </div>
              <div className='project-title'>
                <h4>Space Invaders</h4>
                <div className='project-description'>
                  <p>
                    One month into the course, I was given a week to re-create a
                    classic arcade game using Vanilla JavaScript, HTML, and CSS.
                    I challenged myself, building a grid-based version of space
                    invaders with functionality and styling true to the original
                    game.{' '}
                  </p>
                </div>
              </div>
            </div>
            <div className='project-languages'>
              <ul>
                <li>JavaScript</li>
                <li>HTML</li>
                <li>CSS</li>
              </ul>
            </div>
            <div className='project-links'>
              <a href='https://github.com/hannah-hill/sei-project-01'>
                <FontAwesomeIcon icon={faGithub} className='project-icon' />
              </a>
              <a href='https://hannah-hill.github.io/sei-project-01/'>
                <FontAwesomeIcon icon={faLink} className='project-icon' />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
})

export default Projects
