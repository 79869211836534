import { forwardRef } from 'react'

const About = forwardRef((props, ref) => {
  return (
    <section id='about' ref={ref}>
      <div className='section-container'>
        <div className='section-subheading'>ABOUT</div>
        <div className='section-content about'>
          <p>
            A <strong>Junior Software Engineer</strong> with a background in{' '}
            <strong>Project Management</strong>, I led design production for a
            lifestyle publisher before setting my sights on a career in Software
            Engineering. Working closely with designers and developers, I envied
            the creativity and ownership that my colleagues enjoyed, and
            constantly sought out opportunities to learn new technologies.
          </p>
          <br />
          <p>
            I decided to take the leap and enroll in General Assembly’s{' '}
            <strong>Software Engineering Immersive bootcamp</strong>. I’m now
            seeking a role where I can use my technical skills, aptitude for
            problem-solving and flair for creativity to produce exceptional
            products.
          </p>
          <br />
          <p>
            <strong>Outside of coding</strong>, my favourite things to do
            include skiing, reading novels, and drinking overpriced flat whites.
          </p>
        </div>
      </div>
    </section>
  )
})

export default About
