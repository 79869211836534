import { forwardRef } from 'react'

const Skills = forwardRef((props, ref) => {
  return (
    <section id='technologies' ref={ref}>
      <div className='section-container'>
        <div className='section-subheading'>TECHNOLOGIES</div>
        <div className='section-content skills'>
          <div className='skills-box'>
            <h4>FRONTEND</h4>
            <ul>
              <li className='skill'>HTML</li>
              <li className='skill'>CSS</li>
              <li className='skill'>JavaScript</li>
              <li className='skill'>React</li>
              <li className='skill'>Sass</li>
              <li className='skill'>Bootstrap</li>
            </ul>
          </div>
          <div className='skills-box'>
            <h4>BACKEND</h4>
            <ul>
              <li className='skill'>Node.js</li>
              <li className='skill'>MongoDB</li>
              <li className='skill'>Mongoose</li>
              <li className='skill'>Express</li>
              <li className='skill'>Python</li>
              <li className='skill'>Django</li>
              <li className='skill'>PostgreSQL</li>
            </ul>
          </div>
          <div className='skills-box'>
            <h4>TOOLS</h4>
            <ul>
              <li className='skill'>Yarn</li>
              <li className='skill'>npm</li>
              <li className='skill'>pip</li>
              <li className='skill'>Git & GitHub</li>
              <li className='skill'>Insomnia</li>
              <li className='skill'>Postman</li>
              <li className='skill'>Postbird</li>
            </ul>
          </div>
          <div className='skills-box'>
            <h4>DESIGN</h4>
            <ul>
              <li className='skill'>Figma</li>
              <li className='skill'>Illustrator</li>
              <li className='skill'>InDesign</li>
              <li className='skill'>XD</li>
              <li className='skill'>Lucidchart</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
})
export default Skills
