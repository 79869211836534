import { useRef } from 'react'
import Home from './pages/Home'
import Header from './components/Header'
import SideNav from './components/SideNav'
import Skills from './pages/Skills'
import About from './pages/About'
import Projects from './pages/Projects'
import Contact from './pages/Contact'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp } from '@fortawesome/free-solid-svg-icons'

function App() {
  const aboutRef = useRef()
  const technologiesRef = useRef()
  const projectsRef = useRef()
  const contactRef = useRef()

  const handleAboutClick = () => {
    aboutRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  const handleTechnologiesClick = () => {
    technologiesRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  }

  const handleProjectsClick = () => {
    projectsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  const handleContactClick = () => {
    contactRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <>
      <Header
        handleAboutClick={handleAboutClick}
        handleProjectsClick={handleProjectsClick}
        handleTechnologiesClick={handleTechnologiesClick}
        handleContactClick={handleContactClick}
      />
      <SideNav />
      <main>
        <Home />
        <About ref={aboutRef} />
        <Skills ref={technologiesRef} />
        <Projects ref={projectsRef} />
        <Contact ref={contactRef} />
      </main>
      <footer>
        <FontAwesomeIcon
          icon={faArrowUp}
          className='up-icon'
          onClick={scrollToTop}
        />
      </footer>
    </>
  )
}

export default App
