import React, { forwardRef } from 'react'

const Contact = forwardRef((props, ref) => {
  return (
    <section ref={ref} id='contact'>
      <div className='section-container contact'>
        <div className='section-subheading'>CONTACT</div>
        <div className='section-content'>
          <a href='mailto:hillhj@icloud.com'>EMAIL</a>
          <a href='https://www.linkedin.com/in/hannah-hill/'>LINKEDIN</a>
          <a href='https://github.com/hannah-hill'>GITHUB</a>
        </div>
      </div>
    </section>
  )
})

export default Contact
