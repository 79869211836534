import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub } from '@fortawesome/free-brands-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'

const SideNav = () => {
  return (
    <div className='side-nav'>
      <a href='https://github.com/hannah-hill'>
        <FontAwesomeIcon icon={faGithub} className='social-icon' />
      </a>
      <a href='https://www.linkedin.com/in/hannah-hill/'>
        <FontAwesomeIcon icon={faLinkedin} className='social-icon' />
      </a>
    </div>
  )
}

export default SideNav
